import React, { createContext, useState } from 'react';

export const MobileMenuContext = createContext();

const MobileMenuContextProvider = ({ children }) => {
  const [mobileMenuShown, setMobileMenuShown] = useState(false);

  function toggleMobileMenu() {
    setMobileMenuShown(!mobileMenuShown);
  }

  return (
    <MobileMenuContext.Provider value={{ mobileMenuShown, toggleMobileMenu }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export default MobileMenuContextProvider;
