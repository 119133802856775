export const SectionPhones = () => {
  return (
    
    <ul className='phones'>
      <li className='phones__item'>
        <a href='tel:89104296737' className='phones__link'>
          8-910-429-67-37
        </a>
      </li>
      
      <li className='phones__item'>
        <p className='phones__link'>
          круглосуточно
        </p>
      </li>
    </ul>
  );
}
