import { Map, Placemark, ZoomControl } from 'react-yandex-maps';

export const ParkingMap = () => {
  return (
    <Map defaultState={{ center: [55.814522, 38.999002], zoom: 14, controls: []}} width='100%' height='350px' instanceRef={ref => { ref && ref.behaviors.disable(['scrollZoom']); }} >
        <Placemark geometry={[55.814522, 38.999002]} />
        <ZoomControl options={{}} />
      </Map>
  )
}
