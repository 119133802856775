import { useContext } from 'react';
import { MobileMenuContext } from '../context/mobileMenuContext';

export function MobileDrawer({ children }) {
  const {mobileMenuShown, toggleMobileMenu} = useContext(MobileMenuContext);

  const menuClassName = `mobile-drawer ${mobileMenuShown ? 'mobile-drawer_shown' : ''}`;

  return (
    <div className={menuClassName}>
      <button className='mobile-drawer__close-button' onClick={toggleMobileMenu}>
        &times;
      </button>
      <div className='mobile-drawer__container'>{children}</div>
    </div>
  );
}
