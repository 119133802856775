import { Section } from 'react-scroll-section';


import { SectionBanner } from './SectionBanner';
import { SectionPhones } from './SectionPhones';
import { SectionOrder } from './SectionOrder';
import { SectionPrice } from './SectionPrice'
import { ParkingMap } from './ParkingMap';
import { SectionParking } from './SectionParking';

export const Main = () => {
  return (
    <main>
      <div className='page__max-width-section'>
        <SectionPhones />
      </div>
      <SectionBanner />
      <div className='page__max-width-section'>
        <Section id='order'>
          <SectionOrder />
        </Section>
        <Section id='price'>
          <SectionPrice />
        </Section>        
      </div>
      <Section id='map'>
        <div className='page__max-width-section'>
          <SectionParking/>
      </div>
      <ParkingMap />
        </Section>
      <div className='page__max-width-section'>
      </div>
    </main>
  );
};
