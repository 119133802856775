import tireIcon from '../images/tire.svg';
import craneIcon from '../images/crane-truck.svg';
import towTruckIcon from '../images/tow-truck.svg'

export const SectionPrice = () => {
  return (
    <section className='section section-price'>
      <h2 className='section__title'>Цены</h2>
      <p className='section__subtitle'>
        Эвакуатор по восточному региону Подмосковья, Владимирской и другим
        областям России
      </p>
      <div className='section-price__cards'>
        <div className='price-card'>
          <img alt='' className='price-card__image' src={towTruckIcon} />
          <p className='price-card__price'>3000 ₽</p>
          <p className='price-card__subprice'>
            + 60 руб. за каждый км. перевозки автомобиля на эвакуаторе
          </p>
          <p className='price-card__text'>
            Эвакуации автомобиля с погрузкой краном манипулятором на платформу
          </p>
        </div>        
        <div className='price-card'>
          <img alt='' className='price-card__image' src={tireIcon} />
          <p className='price-card__price'>2500 ₽</p>
          <p className='price-card__subprice'>
            + 60 руб. за каждый км. перевозки автомобиля на эвакуаторе
          </p>
          <p className='price-card__text'>
            Эвакуация автомобиля c погрузкой на сдвижную платформу
          </p>
        </div>
        <div className='price-card'>
          <img alt='' className='price-card__image' src={craneIcon} />
          <p className='price-card__price'>1500 ₽ / час</p>
          <p className='price-card__subprice'>
            + 1200 руб. каждый последующий час, простой 1000 руб. в час.
          </p>
          <p className='price-card__text'>
          Погрузка - разгрузка (эвакуатор используется только как крановый манипулятор)
          </p>
        </div>
      </div>
    </section>
  );
};
