import { useState } from 'react';

export const GetGpsLocation = ({onSuccess, onError}) => {
  const [location, setLocation] = useState();
  const [processing, setProcessing] = useState(false);
  const [checked, setChecked] = useState(false);


  function getLocation(e) {
    if (checked) {
      setChecked(false);
      return;
    }
    setLocation();
    setProcessing(true);
    function success(position) {
      const {latitude, longitude} = position.coords
      setLocation({
        latitude,
        longitude,
      });
      setProcessing(false);
      setChecked(true);
      onSuccess({latitude, longitude})
    }

    function error(error) {
      let message;
      switch (error.code) {
        case 1:
          message = 'Нет разрешения на доступ к геопозиции'
          break;
        case 2:
          message = 'Не удалось определить геопозицию'
          break;
        case 3:
          message = 'Время ожидания истекло. Не удалось определить геопозицию'
          break;
        default:
          message = 'Не удалось определить геопозицию'
      }      
      setProcessing(false);
      onError(message)
    }

    e.preventDefault();
    navigator.geolocation.getCurrentPosition(success, error, {timeout: 5000});
  }

  return (
    <>
      <input
        type='checkbox'
        onChange={getLocation}
        disabled={processing ? true : false}
        checked={checked}
      />
    </>
  );
};
