import { useContext } from 'react';
import {useScrollSection} from 'react-scroll-section'
import { MobileMenuContext } from '../context/mobileMenuContext';

export const MobileMenu = () => {
  const orderSection = useScrollSection('order');
  const priceSection = useScrollSection('price');
  const mapSection = useScrollSection('map');

  const {toggleMobileMenu} = useContext(MobileMenuContext)

  function clickHandler(section) {
    return () => {
      section.onClick()
      toggleMobileMenu()
    }
    
  }

  return (
    <ul className='mobile-menu'>
      <li className='mobile-menu__item'>
        <a  className={`mobile-menu__link ${orderSection.selected? 'mobile-menu__link_active':''}`} onClick={clickHandler(orderSection)}>Заказать</a>
      </li>
      <li className='mobile-menu__item'>
        <a  className={`mobile-menu__link ${priceSection.selected? 'mobile-menu__link_active':''}`} onClick={clickHandler(priceSection)}>Цены</a>
      </li>
      <li className='mobile-menu__item'>
        <a  className={`mobile-menu__link ${mapSection.selected? 'mobile-menu__link_active':''}`} onClick={clickHandler(mapSection)}>Автостоянка</a>
      </li>      
      <li className='mobile-menu__item'>
        <a  className='mobile-menu__link'>О нас</a>
      </li>
    </ul>
  );
};
