import { useScrollSection } from 'react-scroll-section';

export const SectionBanner = () => {
  const orderSection = useScrollSection('order');
  
  return (
    <section className='banner'>
        <div className='banner__content'>
          <div className='banner__title'>Заказ эвакуатора в Орехово-Зуево</div>
          <button
            className='banner__button button button_white'
            onClick={orderSection.onClick}
          >
            Заказать
          </button>
        </div>
      </section>
  )
}