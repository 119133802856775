import { useContext } from 'react';
import { MobileMenuContext } from '../context/mobileMenuContext';
import { HamburgerButton } from './HamburgerButton';
import { useScrollSection } from 'react-scroll-section';
import logo from '../images/logo.svg';


export const Header = () => {
  const orderSection = useScrollSection('order');
  const priceSection = useScrollSection('price');

  const { toggleMobileMenu } = useContext(MobileMenuContext)

  return (

    <header className='header page__max-width-section'>
      <div className='header__mobile-menu-button'>
        <HamburgerButton onclick={toggleMobileMenu}/>        
      </div>
      <ul className='menu'>
        <li className='menu__item'>
          <a href='#' className='menu__link'>
            О нас
          </a>
        </li>
        <li className='menu__item'>
          <a href='#' className='menu__link'>
            Машины
          </a>
        </li>
        <li className='menu__item'>
          <a href='#' className='menu__link' onClick={priceSection.onClick}>
            Цены
          </a>
        </li>
        <li className='menu__item'>
          <a href='#' className='menu__link'>
            Контакты
          </a>
        </li>
      </ul>
      <div className='logo'>
        <img src={logo} alt='' className='logo__image' />
        <p className='logo__text'>Эвакуатор в Орехово-Зуево</p>
      </div>
      <div className='header__buttons'>
        <button className='button button_white' onClick={priceSection.onClick}>
          Цены
        </button>
        <button
          className='button button_secondary'
          onClick={orderSection.onClick}
        >
          Заказать
        </button>
      </div>
    </header>
  );
};
