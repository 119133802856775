export const Footer = () => {
  return (
    <footer className='footer'>
      <div className='page__max-width-section'>
      <h2 className='footer__title'>Компания «Вектор»</h2>
      <ul className='footer__list'>
        <li className='footer__item'>
          Работаем круглосуточно
        </li>
        <li className='footer__item'>
        Телефон вызова эвакуатора:
        </li>
        <li className='footer__item'>
        <a href='tel:89104296737' className='footer__link'>
          8-910-429-67-37
        </a>
        </li>
        <li className='footer__item'>
        <a href='tel:89032936445' className='footer__link'>
          8-903-293-64-45
        </a>
        </li>
       
        <li className='footer__item'>Электронная почта: <a className='footer__link' href='mailto:gavriluksergey71@mail.ru'>gavriluksergey71@mail.ru</a></li>
        
        </ul>
      </div>
    </footer>
  );
};
