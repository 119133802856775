import React, {useState} from 'react'
import { Collapse } from "react-collapse"

export const SectionParking = () => {
  const [showMore, setShowMore] = useState(false)
  function showMoreHandler(e) {
    setShowMore(true);
  }

  return (
    <section className='section section-parking'>
      <h2 className='section__title'>Автостоянка</h2>
      <p className='section__subtitle'>Автостоянка Орехово-Зуево, Клязьменский проезд, 11</p>
      <p className='section__paragraph'>
        Обращаем Ваше внимание на то, что в Московской области введены
        эвакуаторы и штрафстоянки. Теперь администрация может эвакуировать
        машину на штрафстоянку, даже если Вас нет рядом. </p>
      <button className='button button_white' onClick={showMoreHandler}>Что делать если мою машину эвакуировали в Орехово-Зуевском районе?</button>
      <Collapse isOpened={showMore}
          theme={{
            collapse: 'form__collapse',
            content: 'form__collapse-content',
          }}>
        <p className='section__paragraph'>Зачастую автовладелец,
        не обнаруживший машины на месте, тут же в панике звонит в милицию и
        сообщает об угоне. Вряд ли сотрудники правоохранительных органов
        стремглав бросятся разыскивать автомобиль, но в угон объявят наверняка.
        Это чревато тем, что вызволенная со штрафстоянки машина еще долго будет
        числиться "в бегах", а ее владельцу придется разбираться с каждым
        гаишником. В такую историю, кстати, может попасть любой, кто неправильно
        припарковал машину в Московской области. Так что <span className='section__strong'>не торопитесь сразу
        звонить в полицию</span> - сначала, если Вы находитесь в Орехово-Зуевском
        районе, воспользуйтесь телефоном "горячей линии" городской штрафстоянки: <a href='tel:84964246336' className='section__link'>8 (496) 424-63-36</a>
      </p>
      </Collapse>
      
    </section>
  );
};
