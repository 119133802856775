import { useState, useRef, useEffect } from 'react';
import { Collapse } from 'react-collapse';
import { GetGpsLocation } from './GetGpsLocation';

import { useFormAndValidation } from '../hooks/useFormAndValidation';

export const SectionOrder = () => {
  const [showMore, setShowMore] = useState(false)
  const [gpsError, setGpsError] = useState()
  const { values, updateValues, handleChange, errors, isValid } = useFormAndValidation(false)

  function showMoreHandler(e) {
    e.preventDefault()
    setShowMore(!showMore)
  }

  function gpsSuccessHandler({latitude, longitude}) {
    setGpsError(null)
    console.log(latitude, longitude)
    updateValues({latitude, longitude})
  }

  function gpsErrorHandler(errorMessage) {
    setGpsError(errorMessage)
  }

  function handleSubmit(e) {
    e.preventDefault()
    fetch('/mail.php', {
      method: 'POST',
      'content-type': 'application/json',
      body: JSON.stringify(values)
    })
  }

  return (
    <section className='section section-order'>
      <h2 className='section__title'>Заявка на выезд эвакуатора</h2>
      <p className='section__subtitle'>
        Оставьте заявку на вызов эвакуатора на сайте. Достаточно указать только
        контактный номер телефона и мы Вам перезвоним.
      </p>
      <form
        className='form section-order__form'
        onSubmit={handleSubmit}
        noValidate>
        <label className='form__label'>
          Контактный тел: *
          <input
            name='phone'
            className='form__input'
            type='tel'
            minLength='10'
            maxLength='15'
            required
            onChange={handleChange}
            value={values.phone || ''}
          />
          <span className={`form__error ${errors.phone ? 'form__error_active' : ''}`}>{ errors.phone}</span>
        </label>

        <Collapse
          isOpened={showMore}
          theme={{
            collapse: 'form__collapse',
            content: 'form__collapse-content',
          }}
        >
          <label className='form__label'>
            Имя:
            <input
              name='name'
              className='form__input'
              type='text'
              onChange={handleChange}
              value={values.name || ''}
            />
          </label>
          <label className='form__label'>
            Тип транспортного средства:
            <select
              name='vehicle'
              className='form__input'
              onChange={handleChange}
              value={values.vehicle || 'car'}>
              <option value='bike'>Мотоцикл</option>
              <option value='car'>Легковой</option>
              <option value='truck'>Грузовой</option>
              <option value='special'>Спецтехника</option>
            </select>
          </label>
          <label className='form__label'>
            Адрес места эвакуации:
            <input
              name='address'
              className='form__input'
              type='text'
              onChange={handleChange}
              value={values.address || ''}
            />
          </label>
          <label className='form__label'>
            <div>
              <GetGpsLocation
                onSuccess={gpsSuccessHandler}
                onError={gpsErrorHandler}
              />{' '}
              &nbsp; Отправить данные моего текущего местоположения
            </div>
            <span className='form__error form__error_active'>{gpsError}</span>
          </label>
        </Collapse>
        <button
          className={`form__submit button button_secondary ${isValid ? '' : 'form__submit_disabled'}`}
          type='submit'
          disabled={!isValid}
        >
          Отправить
        </button>
        {!showMore && (
          <button className='form__show-more-button' onClick={showMoreHandler}>
            Показать расширенную форму{' '}
          </button>
        )}
      </form>
    </section>
  );
}
