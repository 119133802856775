import MobileMenuContextProvider from '../context/mobileMenuContext';

import {YMaps} from 'react-yandex-maps'

import { Main } from './Main';
import { MobileDrawer } from './MobileDrawer';
import { MobileMenu } from './MobileMenu';
import { Header } from './Header';

import { ScrollingProvider } from 'react-scroll-section';
import { Footer } from './Footer';

function App() {

  return (
    <YMaps>
    <ScrollingProvider>
        <MobileMenuContextProvider>
          <MobileDrawer>
            <MobileMenu />
          </MobileDrawer>
          <Header />
        <Main />
        <Footer />
        </MobileMenuContextProvider>
      </ScrollingProvider>
      </YMaps>
  );
}

export default App;
